import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
  Layout,
  SEO,
  GradientContainer,
  Table,
  TableRow,
  TableHeading,
  TableBody,
  TableCell,
} from '../components';

function RecoveryServicesPage() {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://brandedweb.mindbodyonline.com/embed/widget.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout>
      <SEO title="Book Recovery Centre Services" />
      {/* <div className="mt-24" /> */}
      <div
        className="mindbody-widget"
        data-widget-type="Appointments"
        data-widget-id="3d5201b247"
      />
    </Layout>
  );
}

export default RecoveryServicesPage;
